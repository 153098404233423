import React, { useState } from 'react';
import html2pdf from 'html2pdf.js';
import './Cover.css'

const ConvertToPdfPage = () => {
    const [companyName, setCompanyName] = useState('');
    const [roleName, setRoleName] = useState('');
    const [email, setEmail] = useState('');

    const para = `
        Naresh Kumar S,\n
        Rasipuram, Namakkal,\n
        ${email},\n\n
        ${new Date().toLocaleDateString()} \n\n

        \nSir/Mam; \n\n
        I am reaching out to express my interest in the ${roleName} role at ${companyName}. With a strong foundation in Java programming, problem-solving, React-Native development, GitHub version control, HTML, and CSS, I am eager to contribute to your team's success.\n\n


        Throughout my academic journey in pursuing a Bachelor's degree in Computer Science and Engineering, slated for completion in 2024, I have actively engaged in various projects that have honed my technical skills and collaborative abilities. Notably, I spearheaded the development of KCE Explorer, an application designed to facilitate users in locating labs and halls within the KCE Campus. This experience equipped me with invaluable insights into project management, teamwork, and effective communication.\n\n


        I am drawn to opportunities at ${companyName} due to its reputation for innovation and excellence. I am enthusiastic about the prospect of contributing my skills and enthusiasm to your team, while also embracing new challenges and learning opportunities.\n\n


        Enclosed is my resume, which provides further details about my academic background, skills, and experiences. I am confident that my blend of technical proficiency, problem-solving acumen, and commitment to continuous improvement make me a valuable asset for the ${roleName} role.\n\n


        Thank you for considering my application. I am excited about the possibility of contributing to your team's objectives and am keen to discuss how my qualifications align with the needs of ${companyName}. Please feel free to contact me at 9659020514 or via email at ${email} to schedule a discussion at your convenience.\n\n


        Sincerely,\n

        Naresh Kumar S.`.split('\n\n');;


    const handleCompany = (e) => {
        setCompanyName(e.target.value);
    };

    const handleRole = (e) => {
        setRoleName(e.target.value);
    };

    const handleConvertToPdf = () => {
        
        const options = {
            margin: [20, 20, 10, 20], // Set the margin for top, right, bottom, and left respectively
            filename: 'Naresh Kumar S ' + companyName + ' Cover Letter.pdf',
            jsPDF: { 
                format: 'a4', // page format
                // orientation: 'portrait', // page orientation
            },
            html2canvas: { scale: 2 },
            image: { type: 'jpeg', quality: 0.98 },
            font: 'Arial', // Set the font family
            lineHeight: 2.5, // Set the line height
            lineSpacing: 5, // Set the line spacing
            justify: true,
        };
        const element = document.getElementById('inputContainer');
        html2pdf().set(options).from(element).save();
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    return (
        <div className='container' id='cover' >
            <h1 id='title'>Convert to PDF</h1>

            <div className='textContainer'>
            <textarea value={companyName} placeholder='Enter the Company Name...'
                id='textArea' onChange={handleCompany} />

            <textarea value={roleName} placeholder='Enter the Role Name...'
                id='textArea' onChange={handleRole} />
            </div>
            

            <div>
                <h2>Select Email:</h2>
                <label>
                    <input type="radio" name="format" value="nareshkumar.s1553@gmail.com" onChange={handleEmail} />
                    nareshkumar.s1553@gmail.com
                </label>
                <label>
                    <input type="radio" name="format" value="nareshkumar.s9623@gmail.com" onChange={handleEmail} />
                    nareshkumar.s9623@gmail.com
                </label>
            </div>  
            
            <div id="inputContainer"
                style={{
                    fontFamily: 'Times New Roman', 
                    fontSize: '20px' ,
                    textAlign: 'justify',
                    lineHeight: '1.5',
                    pageBreakInside: 'avoid', // Add this line
                }}
            >
                <br />
                {para.map((p, index) => <p key={index}>{p}</p>)}
                {/* Render the input content here */}
                
            </div>
            <div className='footer'>
            <button id='button' onClick={handleConvertToPdf}>Convert to PDF</button>
            <button id='button' onClick={() => {
                navigator.clipboard.writeText(para.join('\n\n'));
            }}>Copy to Clipboard</button>
            </div>
        </div>
    );
};

export default ConvertToPdfPage;